import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

interface ButtonProps {
  label: string;
  onClick?: ((e: any) => void) | undefined;
  variant?: 'contained' | 'outlined' | 'link';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  dataTestId?: string;
  loading?: boolean;
}

const Button = (props: ButtonProps) => {
  return (
    <button
      type={props.type || 'button'}
      data-testid={props.dataTestId}
      onClick={!props.disabled ? props.onClick : () => {}}
      className={cx({
        [styles.submitBtn]: !props.variant || props.variant === 'contained',
        [styles.outlined]: props.variant === 'outlined',
        [styles.disabled]: props.disabled || props.loading,
        [styles.link]: props.variant === 'link',
      })}
    >
      {props.loading ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        props.label
      )}
    </button>
  );
};

export default Button;
