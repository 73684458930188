import React, { ChangeEvent } from 'react';
import { withRouter } from 'react-router-dom'; // Import withRouter
import { BlockComponent } from '../../../framework/BlockComponent';
import EmailSentIcon from '../../style/icons/email_sent.svg';
import Card from '../../../common/Card';
import styles from '../../../common/card.module.scss';
import emailSentStyles from './emailSent.module.scss';
import Button from '../../../common/Button';
import TextField from '../../../common/TextField';
import UserController from '../../../controllers/user';
import { message } from 'antd';
import FormValidation from '../../../framework/FormValidation';
import { EmailVerificationSchema } from '../../../schema/user';

export const configJSON = require('../../config');

export interface Props {
  location?: any;
  history: any;
}

interface S {
  email: string;
  loader: boolean;
  email_sent: boolean;
  errorText: string;
  updateEnabled: boolean;
  loading: boolean;
  verifiedEmail: string;
}

interface SS {
  id: any;
}

class EmailSent extends BlockComponent<Props, S, SS> {
  // Define redirectTimeout as a class property
  constructor(props: Props) {
    super(props);
    this.state = this.getEmptyState();
  }

  getEmptyState = () => ({
    email: this.props.location.state.email,
    loader: false,
    email_sent: false,
    errorText: '',
    loading: false,
    updateEnabled: false,
    verifiedEmail: this.props.location.state.email,
  });

  toggleUpdate = () => {
    this.setState({
      updateEnabled: !this.state.updateEnabled,
      errorText: '',
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    if (this.state.updateEnabled) {
      const emailField = document.getElementById('email');
      emailField?.focus();
    }
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.target.value,
    });
  };

  handleVerification = async () => {
    try {
      const errObj = FormValidation.validateForm(EmailVerificationSchema, {
        email: this.state.email,
      });
      if (errObj?.email) {
        this.setState({
          errorText: errObj.email,
        });
        return;
      }
      this.setState({ loading: true });
      const controller = new UserController();
      await controller.retryVerification({
        id: this.props.location.state.id,
        email: this.state.email,
      });
      message.success('Verification link is sent to your email');
      this.setState({
        updateEnabled: false,
        loading: false,
        verifiedEmail: this.state.email,
      });
    } catch (err) {
      this.setState({
        updateEnabled: true,
        loading: false,
        errorText: (err as Error).message,
      });
    }
  };

  emailSentMessage = (
    <>
      <div>
        <img src={EmailSentIcon} className={styles.emailIcon} />
        <p
          style={{
            fontWeight: '500',
          }}
          className={emailSentStyles.emailSentSubHeading}
        >
          Link to Verify Your Account has been sent to your registered email,
          Please Check Your Mail
        </p>
      </div>
      <p className={emailSentStyles.emailSentFooterClass}>
        Questions? Reach out to us at &nbsp;
        <a href="mailto:support@bitnimbus.io"> support@bitnimbus.io</a>
      </p>
    </>
  );

  render() {
    const { loader } = this.state;

    return (
      <div>
        <div className="ct_main_bg">
          <div className="container-fluid">
            {loader ? (
              <div className="screen-loader-center">
                <div className="ct_center_loader">
                  <span className="loader"></span>
                </div>
              </div>
            ) : (
              <Card
                title={
                  this.props.location?.state?.event === 'emailResent'
                    ? 'Verify your email to login'
                    : 'Verify your email'
                }
              >
                <>
                  <p
                    style={{
                      fontWeight: '500',
                    }}
                    className={emailSentStyles.emailSentSubHeading}
                  >
                    Link to Verify Your Account has been sent to your registered
                    email, Please Check Your Inbox
                  </p>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: this.state.errorText ? 'center' : 'end',
                        marginBottom: '1.5rem',
                      }}
                    >
                      <div style={{ width: '80%', marginRight: '1rem' }}>
                        <TextField
                          disabled={!this.state.updateEnabled}
                          value={this.state.email}
                          id={'email'}
                          label="Email"
                          onChange={this.handleChange}
                          errorText={this.state.errorText}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              this.handleVerification();
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <button
                          onClick={
                            this.state.updateEnabled
                              ? this.handleVerification
                              : this.toggleUpdate
                          }
                          disabled={this.state.loading}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'transparent',
                            border: 'none',
                            fontSize: '1.2rem',
                            color: !this.state.loading ? '#4c1986' : 'inherit',
                            opacity: !this.state.loading ? 1 : 0.6,
                          }}
                        >
                          <span style={{ marginRight: '8px' }}>
                            {this.state.loading && (
                              <div
                                className="spinner-border spinner-border-sm text-primary"
                                role="status"
                              />
                            )}
                          </span>
                          {this.state.updateEnabled ? 'Verify' : 'Update'}
                        </button>
                        {this.state.updateEnabled && (
                          <button
                            onClick={() => {
                              this.setState({
                                email: this.state.verifiedEmail,
                                updateEnabled: false,
                                errorText: '',
                              });
                            }}
                            disabled={this.state.loading}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: 'transparent',
                              border: 'none',
                              fontSize: '1.2rem',
                              opacity: !this.state.loading ? 1 : 0.6,
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </Card>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// Wrap the component with withRouter to get access to history
export default EmailSent;
