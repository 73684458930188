import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { captchaSiteKey } from '../../config';

interface CaptchaVerificationProps {
  onSuccess: (token: string) => void;
  resetCaptcha?: boolean;
}

const CaptchaVerification = ({
  onSuccess,
  resetCaptcha,
}: CaptchaVerificationProps) => {
  const captchaRef = useRef(null);

  (globalThis as any).onloadCaptchaCallback = function () {
    (globalThis as any).turnstile.render(captchaRef.current, {
      sitekey: captchaSiteKey,
      callback: function (token: string) {
        onSuccess(token);
      },
    });
  };

  useEffect(() => {
    if (resetCaptcha) {
      if ((globalThis as any).turnstile) {
        (globalThis as any).turnstile.reset(captchaRef.current);
      }
    }
  }, [resetCaptcha]);

  const scriptPath =
    'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit&onload=onloadCaptchaCallback';

  return (
    <div style={{ textAlign: 'left' }}>
      <Helmet>
        <script src={scriptPath} async />
      </Helmet>
      <div ref={captchaRef} className="cf-turnstile" />
    </div>
  );
};

export default CaptchaVerification;
