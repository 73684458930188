const apiEndpoints: Record<string, string> = {
  LOCAL: 'http://localhost:9000/users/',
  STAGING: 'https://login-api-staging.bitnimbus.io/users/',
  PROD: 'https://login-api-prod.bitnimbus.io/users/',
};

export const apiGetMethod = 'GET';
export const apiPostMethod = 'POST';
export const apiPutMethod = 'PUT';
export const apiDeleteMethod = 'DELETE';
export const apiPatchMethod = 'PATCH';

export const baseUrl = apiEndpoints[process.env.REACT_APP_ENV || 'LOCAL'];
export const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY || '';
export const jsonDataContentType = 'application/json';
export const formUrlenCodedContentType = 'application/x-www-form-urlencoded';
export const emptyValue = '';

export const registerAccountEndPoint = 'signUp';
export const loginAccountEndPoint = 'login';
export const otpCheckEndPoint = 'verify/otp';
export const otpResendEndpoint = 'resend/otp';
export const passwordResendEndpoint = 'resend/password';
export const forgotPasswordEndPointURL = 'forgotPassword';
export const forgotUsernameEndpoint = 'forgot/username';
export const getUserByACTtokenEndpoint = 'details/act_token';
export const verifyEmailEndPoint = 'confirm/account';
export const createBillingDetailandVerifyUser = 'verify/billing';
export const retryAccountVerification = 'verify/email';

// export const baseUrl1 = "http://localhost:9000/"
// export const baseUrl1 = "http://54.243.134.173:80/"
export const baseUrl1 = 'https://login-api-staging.bitnimbus.io/';
export const dashboard = 'https://login-staging.bitnimbus.io/';
