//@ts-nocheck
import React, { useEffect, useState } from 'react';
import Header from './common/Header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Sidebar from './common/Sidebar';
import ForgotPassword from './components/account/login/ForgotPassword';
import ResetPassword from './components/account/login/ResetPassword';
import VerifyEmail from './components/account/login/VerifyEmail';
import EmailSent from './components/account/login/EmailSent';
import Login from './components/account/login/Login';
import OtpCheck from './components/account/registration/OtpCheck';
import Registration from './components/account/registration/Registration';
import Home from './components/Home/Home';
import Headers from './common/Headers';
import BillingInfo from './components/account/billing-info/BillingInfo';
import Signup from './components/account/registration/Signup';
import { Verify } from 'crypto';
import ForgotUsername from './components/account/login/ForgotUsername';
import CloseAccount from './components/account/CloseAccount';

function WebRoutes() {
  const [protectedRoute, setProtectedRoute] = useState('');
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token != '') {
      setProtectedRoute(token);
    }
  }, []);
  return (
    <Router>
      {!protectedRoute ? (
        <Switch>
          <Route
            path="/account/registration"
            exact
            render={(props) => <Signup {...props} />}
          />
          <Route path="/" exact render={(props) => <Login {...props} />} />
          <Route
            path="/otp/verify"
            exact
            render={(props) => <OtpCheck {...props} />}
          />
          <Route
            path="/forgot/password/:act_token"
            exact
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            path="/forgot/password/"
            exact
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            path="/forgot/username"
            exact
            render={(props) => <ForgotUsername {...props} />}
          />
          <Route
            path="/verifyUser/:act_token"
            exact
            render={(props) => <BillingInfo {...props} />}
          />
          <Route
            path="/resetPassword/:act_token"
            exact
            render={(props) => <BillingInfo {...props} />}
          />
          <Route
            path="/verify/account/:act_token"
            exact
            render={(props) => <VerifyEmail {...props} />}
          />
          <Route
            path="/verify/account"
            exact
            render={(props) => <EmailSent {...props} />}
          />
          <Route path="/account/closed" exact render={CloseAccount} />
        </Switch>
      ) : (
        <div>
          <Headers />
          <Header />
          <Sidebar />
        </div>
      )}
    </Router>
  );
}
export default WebRoutes;
