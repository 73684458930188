import React, { ReactElement } from 'react';

import Logo from '../components/style/logo.png';
import styles from './card.module.scss';

interface CardProps {
  children: ReactElement;
  title?: string;
}

const Card = (props: CardProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div>
          <div>
            <img className={styles.logo} id="logo" src={Logo} />
          </div>
          {props.title && <h3 className={styles.title}>{props.title}</h3>}
        </div>
        <div
        // style={{
        //   height: '100%',
        //   display: 'flex',
        //   justifyContent: 'space-evenly',
        //   flexDirection: 'column',
        // }}
        >
          {props.children}
          <div>
            <p style={{ fontSize: '1.2rem' }}>
              Facing issues? Please contact our support at{' '}
              <a
                style={{ fontSize: '1.2rem', color: '#4c1986' }}
                href="mailto:support@bitnimbus.io"
              >
                support@bitnimbus.io
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CardContent = (props: CardProps) => {
  return <div style={{ marginBottom: '1.5rem' }}>{props.children}</div>;
};

export default Card;
