import React, { ReactElement } from 'react';

import InfoIcon from '../components/style/icons/info.svg';
import styles from './textfield.module.scss';
import Tooltip from './Tooltip';

interface TextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  value: string;
  id: string;
  label: string;
  placeholder?: string | '';
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorText?: string;
  infoText?: string | ReactElement;
  dataTestId?: string;
  required?: boolean;
  disabled?: boolean;
}

const TextField = (props: TextFieldProps) => {
  const { value, onChange, id, label, placeholder } = props;

  return (
    <div className={styles.group}>
      <label
        htmlFor={id}
        className={styles.label}
        aria-required={props.required}
      >
        {label}
        {props.required && (
          <span style={{ color: props.errorText ? 'red' : 'inherit' }}>*</span>
        )}
        {props.infoText && (
          <Tooltip title={props.infoText}>
            <img className={styles.tooltipIcon} src={InfoIcon} />
          </Tooltip>
        )}
      </label>
      <input
        name={id}
        disabled={props.disabled}
        type="text"
        className={`form-control ${styles.formControl}`}
        placeholder={placeholder}
        style={{ borderRadius: '8px' }}
        data-testid={props.dataTestId}
        {...props}
      />
      {props.errorText && (
        <span className={styles.helperText}>{props.errorText}</span>
      )}
    </div>
  );
};

export default TextField;
